import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Input,
    Select,
    DatePicker,
    InputNumber,
    Button,
    Table,
    message,
    Form,
    Modal,
    notification,
} from 'antd'
import { goBack } from '../../Util/ILUtil'

function CreatePartnerCoupon({ navigate }) {
    const [form] = Form.useForm()
    const { RangePicker } = DatePicker
    const { Option } = Select
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [purchaseDate, setPurchaseDate] = useState('')
    const [partners, setPartners] = useState([])
    const [course, setCourse] = useState()
    const [attempt, setAttempt] = useState()
    const [courses, setCourses] = useState([])
    const [attempts, setAttempts] = useState([])
    const [selectCourseVisible, setSelectCourseVisible] = useState(false)
    const [selectAttemptVisible, setSelectAttemptVisible] = useState(false)
    const [totalNetPrice, setTotalNetPrice] = useState('')

    useEffect(() => {
        RestApi.doGet(GLOBAL.URL.GET_PARTNERS).then((res) => {
            setPartners(res.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        calculateNetPrice()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attempt])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const onChangeDateRange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const onChangePurchaseDate = (value, dateString) => {
        setPurchaseDate(dateString)
    }

    const getPartnerOptions = () => {
        var partnerOptions = []
        partners.forEach((item) => {
            partnerOptions.push(
                <Option value={item.id} key={item.id}>
                    {item.name}
                </Option>
            )
        })

        return partnerOptions
    }

    const handlePartnerChange = (value) => {
        const selectedPartnerData = partners.find(
            (partner) => partner.id === value
        )
        form.setFieldsValue({
            netPercent: selectedPartnerData.netPercent,
        })

        calculateNetPrice()
    }

    const showSelectCourse = () => {
        setSelectCourseVisible(true)
    }

    const hideSelectCourse = () => {
        setSelectCourseVisible(false)
    }

    const searchCatalogCourse = (query) => {
        if (!query) {
            return
        }

        setLoading(true)

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.COURSE.SEARCH_CATALOG_COURSES, formData)
            .then((res) => {
                setCourses(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const selectCourse = (record) => {
        setCourse(record)
        setAttempt()
        hideSelectCourse()
    }

    const searchCourseColumns = [
        {
            title: 'Select',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => selectCourse(record)}
                >
                    Select
                </Button>
            ),
        },
        {
            title: 'Course Name',
            dataIndex: 'name',
            key: 'name',
        },
    ]

    const showSelectAttempt = () => {
        if (!course || !course.courseId) {
            return
        }

        setAttempts([])
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.COURSE.GET_ENABLED_PRICES + course.courseId)
            .then((res) => {
                setAttempts(res.data)
            })
            .finally(() => {
                setLoading(false)
            })

        setSelectAttemptVisible(true)
    }

    const hideSelectAttempt = () => {
        setSelectAttemptVisible(false)
    }

    const attemptColumns = [
        {
            title: 'Select',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => selectAttempt(record)}
                >
                    Select
                </Button>
            ),
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
            width: 150,
        },
        {
            title: 'price',
            dataIndex: 'priceString',
            key: 'priceString',
        },
    ]

    const selectAttempt = (record) => {
        setAttempt(record)
        hideSelectAttempt()
    }

    const calculateNetPrice = () => {
        if (!attempt || !attempt.attempt) {
            setTotalNetPrice('--Select Attempt--')
            return
        }

        var quantity = form.getFieldValue('quantity')
        if (!quantity) {
            setTotalNetPrice('--Enter Quantity--')
            return
        }

        var netPercent = form.getFieldValue('netPercent')
        var netPrice = form.getFieldValue('netPrice')

        if ((!netPercent && !netPrice) || (netPercent && netPrice)) {
            setTotalNetPrice('--Need either Net Percent or Net Price--')
            return
        }

        var price = 0
        if (netPrice) {
            price = quantity * netPrice
        } else {
            price = (quantity * netPercent * attempt.price) / 100
        }

        setTotalNetPrice(price)
    }

    const createCoupons = () => {
        form.validateFields()
            .then((values) => {
                if (values.netPercent === 0 && values.netPrice === 0) {
                    message.error(
                        'Need net percent or net price, both cannot be 0'
                    )
                    return
                }

                if (values.netPercent > 0 && values.netPrice > 0) {
                    message.error(
                        'Need either net percent or net price, both cannot be set'
                    )
                    return
                }

                setLoading(true)
                values['startDate'] = startDate
                values['endDate'] = endDate
                values['purchaseDate'] = purchaseDate
                values['courseId'] = course.courseId
                values['attemptName'] = attempt.attempt

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.CREATE_PARTNER_COUPONS, body)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })
                        goBack(navigate)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    return (
        <div>
            <Button size="small" onClick={() => goBack(navigate)}>
                {'< Back'}
            </Button>
            <h2>Create Partner Coupons</h2>
            <Form form={form} layout="horizontal" {...formItemLayout}>
                <Form.Item
                    label="Quantity"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="quantity"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <InputNumber
                        autoFocus
                        min={1}
                        onChange={calculateNetPrice}
                    />
                </Form.Item>
                <Form.Item
                    label="Prepend String"
                    style={{ marginBottom: 10 }}
                    name="prepend"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input style={{ width: 90 }} />
                </Form.Item>
                <Form.Item
                    label="Coupon Length"
                    style={{ marginBottom: 10 }}
                    name="charLength"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    label="Date range"
                    style={{ marginBottom: 10 }}
                    name="dateRange"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <RangePicker
                        showTime={{ format: 'HH:mm' }}
                        format={'DD-MMM-YY HH:mm'}
                        placeholder={['Start Date', 'End Date']}
                        onChange={onChangeDateRange}
                    />
                </Form.Item>
                <Form.Item
                    label="Purchase Date"
                    style={{ marginBottom: 10 }}
                    name="purchaseDate"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <DatePicker
                        showTime
                        placeholder="Select Date"
                        format="DD-MMM-YY HH:mm"
                        onChange={onChangePurchaseDate}
                    />
                </Form.Item>
                <Form.Item
                    label="Partner"
                    style={{ marginBottom: 10 }}
                    name="partnerId"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select partner"
                        onChange={(value) => {
                            handlePartnerChange(value)
                        }}
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {getPartnerOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Net Percent (our share)"
                    style={{ marginBottom: 10 }}
                    name="netPercent"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <InputNumber min={0} onChange={calculateNetPrice} />
                </Form.Item>
                <Form.Item
                    label="Net Price ₹ (our share)"
                    shouldUpdate={true}
                    style={{ marginBottom: 10 }}
                    name="netPrice"
                    valuePropName="value"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        precision={2}
                        onChange={calculateNetPrice}
                    />
                </Form.Item>
                <Form.Item
                    label="Partner Transaction #"
                    style={{ marginBottom: 10 }}
                    name="partnerTransactionId"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input placeholder="Partner Transaction ID" />
                </Form.Item>
                <div style={{ marginTop: 20 }}>
                    <span
                        style={{
                            marginLeft: 150,
                        }}
                    >
                        Course :
                    </span>
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                            marginLeft: 10,
                            fontWeight: 'bold',
                            fontSize: 16,
                        }}
                        onClick={showSelectCourse}
                    >
                        {course ? course.name : '--Select Course--'}
                    </Button>
                </div>
                {course && course.courseId ? (
                    <>
                        <div style={{ marginTop: 10 }}>
                            <span style={{ marginLeft: 145 }}>Attempt :</span>
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                    marginLeft: 10,
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                }}
                                onClick={showSelectAttempt}
                            >
                                {attempt
                                    ? attempt.attempt +
                                      ' (' +
                                      attempt.priceString +
                                      ')'
                                    : '--Select Attempt--'}
                            </Button>
                        </div>
                        {attempt && attempt.attempt ? (
                            <div style={{ marginTop: 10 }}>
                                <span style={{ marginLeft: 138 }}>
                                    Net Price :
                                </span>
                                <span
                                    style={{
                                        marginLeft: 10,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {totalNetPrice}
                                </span>
                            </div>
                        ) : null}
                    </>
                ) : null}
                <div style={{ marginTop: 20 }}>
                    <Button
                        type={'primary'}
                        style={{ marginLeft: 213 }}
                        loading={loading}
                        onClick={createCoupons}
                    >
                        Create Coupons
                    </Button>
                </div>
            </Form>
            <Modal
                title={'Select Course'}
                open={selectCourseVisible}
                onCancel={hideSelectCourse}
                destroyOnClose={true}
                width={900}
                footer={null}
            >
                <Search
                    placeholder="Search Course"
                    onSearch={(value) => searchCatalogCourse(value)}
                />
                <Table
                    columns={searchCourseColumns}
                    dataSource={courses}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Select Attempt'}
                open={selectAttemptVisible}
                onCancel={hideSelectAttempt}
                destroyOnClose={true}
                width={900}
                footer={null}
            >
                <Table
                    columns={attemptColumns}
                    dataSource={attempts}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
        </div>
    )
}

export default CreatePartnerCoupon
