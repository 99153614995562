import {
    Input,
    DatePicker,
    InputNumber,
    Radio,
    Form,
    Select,
    Button,
    message,
    Space,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import clone from 'clone'
import dayjs from 'dayjs'
import { goBack } from '../../Util/ILUtil'

const AddTransactionToQueue = ({ navigate, location }) => {
    const [couponName, setCouponName] = useState('')
    const [couponTypes, setCouponTypes] = useState([])
    const [courseNames, setCourseNames] = useState([])
    const [attempts, setAttempts] = useState([])
    const [loading, setLoading] = useState(false)
    const [types, setTypes] = useState([])
    const [paymentGateways, setPaymentGateways] = useState([])
    const [queue, setQueue] = useState({})
    const [transactionDate, setTransactionDate] = useState('')
    const [form] = Form.useForm()

    useEffect(() => {
        RestApi.doGet(GLOBAL.URL.GET_COUPON_TYPES).then((res) => {
            setCouponTypes(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES).then((res) => {
            setCourseNames(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            var attemptsData = []
            res.data.forEach((item) => {
                attemptsData.push(item.attempt)
            })
            setAttempts(attemptsData)
        })

        RestApi.doGet(GLOBAL.URL.GET_TRANSACTION_TYPES).then((res) => {
            setTypes(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_PAYMENT_GATEWAYS).then((res) => {
            setPaymentGateways(res.data)
        })

        var record = location.state ? location.state.record : {}
        setQueue(record)

        if (record.id) {
            setFormFieldValues(record)
        }
        // eslint-disable-next-line
    }, [])

    const setFormFieldValues = (record) => {
        var length = record.courses.length
        var courseKeysData = []
        for (var i = 0; i < length; i++) {
            courseKeysData.push(i)
        }

        setTransactionDate(record.transactionDateString)

        var selectedRecord = clone(record)
        selectedRecord.email = record.user.email
        selectedRecord.mrp = record.mrpInt
        selectedRecord.price = record.priceDouble
        selectedRecord.pincode = record.pincode.pincode
        selectedRecord.transactionDate = dayjs(
            record.transactionDateString,
            'DD-MMM-YY HH:mm'
        )
        selectedRecord.courses.forEach((course) => {
            course.courseMrp = course.mrpInt
            course.coursePrice = course.priceDouble
        })

        setTimeout(() => {
            form.setFieldsValue(selectedRecord)
        }, 500)
    }

    const onChangeDate = (value, dateString) => {
        setTransactionDate(dateString)
    }

    const onCouponNameChange = (e) => {
        setCouponName(e.target.value)
    }

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)
                values.transactionDate = transactionDate

                var url = GLOBAL.URL.TRANSACTION_QUEUE.ADD_TRANSACTION_TO_QUEUE
                if (queue.id) {
                    values.id = queue.id
                    url =
                        GLOBAL.URL.TRANSACTION_QUEUE.UPDATE_TRANSACTION_TO_QUEUE
                }

                let payload = new FormData()
                payload.append('payload', JSON.stringify(values))

                RestApi.doPost(url, payload)
                    .then((response) => {
                        goBack(navigate)
                        message.success(response.data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const { Option } = Select

    var couponTypeView = []
    couponTypes.forEach((item) => {
        couponTypeView.push(
            <Radio.Button value={item} key={item}>
                {item.toUpperCase()}
            </Radio.Button>
        )
    })

    var typeOptions = []
    types.forEach((item) => {
        typeOptions.push(
            <Radio.Button value={item} key={item}>
                {item.toUpperCase()}
            </Radio.Button>
        )
    })

    var gatewayOptions = []
    paymentGateways.forEach((item) => {
        gatewayOptions.push(
            <Radio.Button value={item} key={item}>
                {item.toUpperCase()}
            </Radio.Button>
        )
    })

    var courseOptions = []
    courseNames.forEach((item) => {
        courseOptions.push(
            <Option value={item} key={item}>
                {item}
            </Option>
        )
    })

    var attemptOptions = []
    attempts.forEach((item) => {
        attemptOptions.push(
            <Option value={item} key={item}>
                {item}
            </Option>
        )
    })

    const courseItems = () => {
        return (
            <Form.List name="courses">
                {(fields, { add, remove }) => (
                    <>
                        {fields.length === 0 && add()}
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Space key={key}>
                                <Form.Item
                                    {...restField}
                                    label=""
                                    key={key}
                                    name={[name, 'courseName']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Needed!',
                                        },
                                    ]}
                                    style={{ width: '450px' }}
                                >
                                    <Select
                                        placeholder="Select Course"
                                        showSearch
                                        style={{ width: '450px' }}
                                    >
                                        {courseOptions}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label=""
                                    {...restField}
                                    key={key}
                                    name={[name, 'attempt']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Needed!',
                                        },
                                    ]}
                                    style={{ width: '180px' }}
                                >
                                    <Select
                                        placeholder="Select Attempt"
                                        showSearch
                                        style={{ width: '180px' }}
                                    >
                                        {attemptOptions}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label=""
                                    key={key}
                                    {...restField}
                                    name={[name, 'courseMrp']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Needed!',
                                        },
                                    ]}
                                    style={{ width: '100px' }}
                                >
                                    <InputNumber
                                        min={0}
                                        placeholder="MRP"
                                        style={{ width: '100px' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label=""
                                    key={key}
                                    {...restField}
                                    name={[name, 'coursePrice']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Needed!',
                                        },
                                    ]}
                                    style={{ width: '120px' }}
                                >
                                    <InputNumber
                                        min={0}
                                        placeholder="Price"
                                        style={{ width: '120px' }}
                                    />
                                </Form.Item>
                                <Form.Item label={''}>
                                    {fields.length > 1 && (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(name)}
                                        />
                                    )}
                                </Form.Item>
                            </Space>
                        ))}

                        <Form.Item>
                            <Button
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                                style={{ width: '30%' }}
                            >
                                Add Course
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        )
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 10 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 16 },
            sm: { span: 20 },
        },
    }

    return (
        <div>
            <h2>Add Transaction</h2>
            <div>
                Note:
                <ul>
                    <li>
                        For transactions where payment is already received, use
                        the same transaction ID as in payment gateway
                    </li>
                    <li>
                        If Transaction ID is left blank,then it will be auto
                        generated
                    </li>
                    <li>
                        Payment GateWay ID is the reference ID from Payment
                        Gateway transaction
                    </li>
                </ul>
            </div>
            <Form form={form} layout="horizontal" {...formItemLayout}>
                <Form.Item
                    label="Email"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter email!',
                        },
                    ]}
                >
                    <Input autoFocus style={{ width: 400 }} />
                </Form.Item>
                <Form.Item
                    label="Transaction ID"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="transactionId"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input style={{ width: 400 }} />
                </Form.Item>
                <Form.Item
                    label="Transaction Date"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="transactionDate"
                    rules={[
                        {
                            required: true,
                            message: 'Please select transaction date!',
                        },
                    ]}
                >
                    <DatePicker
                        showTime
                        placeholder="Select Date"
                        onChange={onChangeDate}
                        format="DD-MMM-YY HH:mm"
                    />
                </Form.Item>
                <Form.Item
                    label="MRP"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="mrp"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter MRP!',
                        },
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                    label="Price"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="price"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Price!',
                        },
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                    label="Coupon Name"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="couponName"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input
                        onChange={onCouponNameChange}
                        style={{ width: 400 }}
                    />
                </Form.Item>
                <Form.Item
                    label="Coupon Value"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="couponValue"
                    rules={[
                        {
                            required: couponName ? true : false,
                            message: 'Please enter Coupon Value!',
                        },
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                    label="Coupon Type"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="couponType"
                    rules={[
                        {
                            required: couponName ? true : false,
                            message: 'Please select Coupon Type!',
                        },
                    ]}
                >
                    <Radio.Group>{couponTypeView}</Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Transaction Type"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: 'Please select Type!',
                        },
                    ]}
                >
                    <Radio.Group>{typeOptions}</Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Pincode"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="pincode"
                    rules={[
                        {
                            required: true,
                            message: 'Pincode needed!',
                        },
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                    label="Payment Gateway"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="paymentGateway"
                    rules={[
                        {
                            required: true,
                            message: 'Please select!',
                        },
                    ]}
                >
                    <Radio.Group>{gatewayOptions}</Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Payment Gateway ID"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="paymentGatewayId"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input style={{ width: 400 }} />
                </Form.Item>
                <h3>Courses</h3>
                {courseItems()}
            </Form>
            <Button
                type="primary"
                onClick={() => handleSubmit()}
                loading={loading}
            >
                {queue.id ? 'Update' : 'Add'} Transaction
            </Button>
            <Button
                type="primary"
                onClick={() => goBack(navigate)}
                loading={loading}
                style={{ marginLeft: 20 }}
            >
                Cancel
            </Button>
        </div>
    )
}

export default AddTransactionToQueue
