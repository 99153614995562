import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Radio,
    InputNumber,
    DatePicker,
    Form,
    Select,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import IlEmployeeCompOff from './ILEmployeeCompOff'
import { makeStyles } from 'tss-react/mui'
import { EditTwoTone } from '@ant-design/icons'
import dayjs from 'dayjs'

function IlEmployeeLeave(props) {
    const { classes } = useStyles()
    const [form] = Form.useForm()
    const { Option } = Select

    const selectedRecord = props.selectedRecord
    const [leaveBalance, setLeaveBalance] = useState(null)
    const [leavesHistory, setLeavesHistory] = useState([])
    const [leavesHistoryNextUrl, setLeavesHistoryNextUrl] = useState()
    const [loadingLeaves, setLoadingLeaves] = useState(false)
    const [creditLeavesModalVisible, setCreditLeavesModalVisible] =
        useState(false)
    const [debitLeavesModalVisible, setDebitLeavesModalVisible] =
        useState(false)
    const [addLeaveModalVisible, setAddLeaveModalVisible] = useState(false)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [isHalfDay, setIsHalfDay] = useState(false)
    const [selectedLeaveRecord, setSelectedLeaveRecord] = useState()
    const [updateLeaveModalVisible, setUpdateLeaveModalVisible] =
        useState(false)
    const [compOffModalVisible, setCompOffModalVisible] = useState(false)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

    useEffect(() => {
        getLeavesBalance(selectedRecord.user.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear])

    const getLeavesBalance = (userId) => {
        setLoadingLeaves(true)

        RestApi.doGet(
            GLOBAL.URL.IL.GET_LEAVE_BALANCE + userId + '&year=' + selectedYear
        )
            .then((res) => {
                setLeaveBalance(res.data)

                RestApi.doGet(GLOBAL.URL.IL.GET_LEAVE_HISTORY + userId)
                    .then((response) => {
                        var resObj = JSON.parse(response.data)
                        setLeavesHistory(resObj.data)
                        setLeavesHistoryNextUrl(resObj.next)
                    })
                    .finally(() => {
                        setLoadingLeaves(false)
                    })
            })
            .catch(() => {
                setLoadingLeaves(false)
            })
    }

    const getNextPageLeavesHistory = () => {
        if (!leavesHistoryNextUrl) {
            return
        }

        setLoadingLeaves(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + leavesHistoryNextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setLeavesHistoryNextUrl(res.next)
                setLeavesHistory((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoadingLeaves(false)
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getLeaveTableCell = (count, description) => {
        return (
            <tr>
                <td className={classes.leaveBalanceTableCell}>{description}</td>
                <td className={classes.leaveBalanceTableCell}>
                    {count ? count : ''}
                </td>
            </tr>
        )
    }

    const getLeaveBalanceTable = () => {
        return (
            <table
                style={{
                    border: '1px solid black',
                    borderCollapse: 'collapse',
                }}
            >
                <tbody>
                    {getLeaveTableCell(
                        leaveBalance.leavesCredited,
                        'Casual Leaves'
                    )}
                    {getLeaveTableCell(leaveBalance.compOffs, 'Comp Offs')}
                    {getLeaveTableCell(leaveBalance.leavesDebited, 'Debited')}
                    {getLeaveTableCell(leaveBalance.lop, 'LOP')}
                    <tr>
                        <td
                            className={classes.leaveBalanceTableCell}
                            style={{ fontWeight: 600 }}
                        >
                            {'Total Leave Balance'}
                        </td>
                        <td
                            className={classes.leaveBalanceTableCell}
                            style={{ fontWeight: 600 }}
                        >
                            {leaveBalance.leavesBalance
                                ? leaveBalance.leavesBalance
                                : '-'}
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    const leaveHistoryColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateLeaveModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 11, marginRight: 5 }} />
                    </Button>
                    {record.startDate}
                    {record.endDate && record.startDate !== record.endDate ? (
                        <>
                            <br />
                            <span style={{ marginLeft: 18 }}>
                                {record.endDate}
                            </span>
                        </>
                    ) : (
                        ''
                    )}
                </span>
            ),
        },
        {
            title: 'Days',
            dataIndex: 'leavesCount',
            key: 'leavesCount',
            render: (text, record) => (
                <span>
                    <span style={{ color: 'gray' }}>
                        {record.creditDebitType === 'credit'
                            ? '+ '
                            : record.creditDebitType === 'debit'
                            ? '- '
                            : ''}
                    </span>
                    {record.leavesCount}
                </span>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text, record) => <span>{record.type}</span>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span style={{ fontSize: 10 }}>
                    {record.updatedBy.firstName}
                    <br />
                    <span>{record.updatedAt}</span>
                </span>
            ),
        },
        {
            title: '',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteLeaveEntry(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const deleteLeaveEntry = (uid) => {
        setLoadingLeaves(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.IL.DELETE_LEAVE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getLeavesBalance(selectedRecord.user.id)
            })
            .catch((error) => {
                setLoadingLeaves(false)
            })
    }

    const showCreditLeaevesModal = () => {
        setCreditLeavesModalVisible(true)
        form.resetFields()
    }

    const hideCreditLeaevesModal = () => {
        setCreditLeavesModalVisible(false)
    }

    const creditLeaves = () => {
        form.validateFields()
            .then((values) => {
                setLoadingLeaves(true)

                values.userId = selectedRecord.user.id

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.IL.CREDIT_LEAVES, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideCreditLeaevesModal()
                        getLeavesBalance(selectedRecord.user.id)
                    })
                    .catch((error) => {
                        setLoadingLeaves(false)
                    })
            })
            .catch((error) => {})
    }

    const showDebitLeavesModal = () => {
        setDebitLeavesModalVisible(true)
        form.resetFields()
    }

    const hideDebitLeavesModal = () => {
        setDebitLeavesModalVisible(false)
    }

    const debitLeaves = () => {
        form.validateFields()
            .then((values) => {
                setLoadingLeaves(true)

                values.userId = selectedRecord.user.id

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.IL.DEBIT_LEAVES, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideDebitLeavesModal()
                        getLeavesBalance(selectedRecord.user.id)
                    })
                    .catch((error) => {
                        setLoadingLeaves(false)
                    })
            })
            .catch((error) => {})
    }

    const showAddLeavesModal = () => {
        setIsHalfDay(false)
        setAddLeaveModalVisible(true)
        form.resetFields()
    }

    const hideAddLeavesModal = () => {
        setAddLeaveModalVisible(false)
    }

    const onChangeStartDate = (value, dateString) => {
        setStartDate(dateString)
    }

    const onChangeEndDate = (value, dateString) => {
        setEndDate(dateString)
    }

    const addLeave = () => {
        form.validateFields()
            .then((values) => {
                if (!startDate) {
                    notification['error']({
                        message: 'Start Date Needed!',
                    })

                    return
                }

                values.userId = selectedRecord.user.id
                values.startDate = startDate

                var url = GLOBAL.URL.IL.ADD_LEAVE_HALF_DAY
                if (!isHalfDay) {
                    if (!endDate) {
                        notification['error']({
                            message: 'End Date Needed!',
                        })

                        return
                    }

                    url = GLOBAL.URL.IL.ADD_LEAVE
                    values.endDate = endDate
                }

                setLoadingLeaves(true)

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddLeavesModal()
                        getLeavesBalance(selectedRecord.user.id)
                    })
                    .catch((error) => {
                        setLoadingLeaves(false)
                    })
            })
            .catch((error) => {})
    }

    const showUpdateLeaveModal = (record) => {
        setSelectedLeaveRecord(record)
        setStartDate(record.startDate)
        setEndDate(record.endDate)

        setUpdateLeaveModalVisible(true)

        setTimeout(() => {
            var startDateObj = dayjs(record.startDate, 'DD-MMM-YYYY')
            var endDateObj = dayjs(record.endDate, 'DD-MMM-YYYY')

            form.setFieldsValue({
                totalLeaves: record.leavesCount,
                startDate: startDateObj,
                endDate: endDateObj,
                description: record.description,
            })
        }, 100)
    }

    const hideUpdateLeaveModal = () => {
        setUpdateLeaveModalVisible(false)
    }

    const updateLeave = () => {
        form.validateFields()
            .then((values) => {
                if (!startDate) {
                    notification['error']({
                        message: 'Start Date Needed!',
                    })

                    return
                }

                if (!endDate) {
                    notification['error']({
                        message: 'End Date Needed!',
                    })

                    return
                }

                values.startDate = startDate
                values.endDate = endDate
                values.uid = selectedLeaveRecord.uid

                setLoadingLeaves(true)

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.IL.UPDATE_LEAVE, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateLeaveModal()
                        getLeavesBalance(selectedRecord.user.id)
                    })
                    .catch((error) => {
                        setLoadingLeaves(false)
                    })
            })
            .catch((error) => {})
    }

    const showCompOffsModal = () => {
        setCompOffModalVisible(true)
    }

    const hideCompOffsModal = () => {
        setCompOffModalVisible(false)
    }

    const getYearOptions = () => {
        var options = []
        options.push(
            <Option value={2024} key={2024}>
                {2024}
            </Option>
        )

        options.push(
            <Option value={2025} key={2025}>
                {2025}
            </Option>
        )

        return options
    }

    return (
        <>
            <Modal
                title={
                    'Leaves - ' +
                    selectedRecord.user.firstName +
                    ' ' +
                    selectedRecord.user.lastName
                }
                open={true}
                onCancel={() => props.hideLeavesModal()}
                destroyOnClose={true}
                footer={null}
                width={1000}
            >
                {selectedRecord.user ? (
                    <>
                        <div>
                            <Button
                                type="default"
                                size="small"
                                onClick={showCreditLeaevesModal}
                            >
                                Credit Leaves
                            </Button>
                            <Button
                                type="default"
                                size="small"
                                onClick={showDebitLeavesModal}
                                style={{ marginLeft: 10 }}
                            >
                                Debit Leaves
                            </Button>
                            <Button
                                type="default"
                                size="small"
                                onClick={showAddLeavesModal}
                                style={{ marginLeft: 10 }}
                            >
                                Mark Leaves
                            </Button>
                            <Button
                                type="default"
                                size="small"
                                onClick={showCompOffsModal}
                                style={{ marginLeft: 10 }}
                            >
                                Comp Offs
                            </Button>
                            <Button
                                type="default"
                                size="small"
                                onClick={() =>
                                    getLeavesBalance(selectedRecord.user.id)
                                }
                                style={{ marginLeft: 10 }}
                                loading={loadingLeaves}
                            >
                                Refresh
                            </Button>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <span>Year - </span>
                            <Select
                                placeholder="Year"
                                showSearch
                                style={{ width: 100, marginLeft: 5 }}
                                onChange={(value) => setSelectedYear(value)}
                                value={selectedYear}
                            >
                                {getYearOptions()}
                            </Select>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            {leaveBalance ? getLeaveBalanceTable() : null}
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <h4>History</h4>
                            <Table
                                columns={leaveHistoryColumns}
                                dataSource={leavesHistory}
                                pagination={false}
                                size="small"
                                loading={loadingLeaves}
                                rowKey="id"
                            />
                            {leavesHistoryNextUrl ? (
                                <Button
                                    type="primary"
                                    loading={loadingLeaves}
                                    onClick={getNextPageLeavesHistory}
                                    size="small"
                                    style={{ marginTop: 10 }}
                                >
                                    Show More
                                </Button>
                            ) : null}
                        </div>
                    </>
                ) : null}
            </Modal>
            <Modal
                title={'Credit Leaves'}
                open={creditLeavesModalVisible}
                onOk={creditLeaves}
                confirmLoading={loadingLeaves}
                onCancel={hideCreditLeaevesModal}
                okText={'Credit'}
                destroyOnClose={true}
                width={600}
            >
                {creditLeavesModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Leave Count"
                            style={{ marginBottom: 5 }}
                            name="leavesCount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <InputNumber
                                min={1}
                                precision={2}
                                step={0.5}
                                autofocus
                            />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title={'Debit Leaves'}
                open={debitLeavesModalVisible}
                onOk={debitLeaves}
                confirmLoading={loadingLeaves}
                onCancel={hideDebitLeavesModal}
                okText={'Debit'}
                destroyOnClose={true}
                width={600}
            >
                {debitLeavesModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Leave Count"
                            style={{ marginBottom: 5 }}
                            name="leavesCount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <InputNumber
                                min={1}
                                precision={2}
                                step={0.5}
                                autofocus
                            />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title={'Mark Leaves'}
                open={addLeaveModalVisible}
                onOk={addLeave}
                confirmLoading={loadingLeaves}
                onCancel={hideAddLeavesModal}
                okText={'Mark'}
                destroyOnClose={true}
                width={600}
            >
                {addLeaveModalVisible ? (
                    <Form
                        form={form}
                        layout="horizontal"
                        {...formItemLayout}
                        initialValues={{
                            addLeave: isHalfDay,
                        }}
                    >
                        <Form.Item
                            label="Add Leave"
                            style={{ marginBottom: 5 }}
                            name="addLeave"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            valuePropName="value"
                        >
                            <Radio.Group
                                onChange={(e) => setIsHalfDay(e.target.value)}
                            >
                                <Radio.Button value={false} key={'Full_Days'}>
                                    {'Full Day(s)'}
                                </Radio.Button>
                                <Radio.Button value={true} key={'Half_Day'}>
                                    {'Half Day'}
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="Start Date"
                            style={{ marginBottom: 5 }}
                            name="startDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <DatePicker
                                format="DD-MMM-YYYY"
                                onChange={onChangeStartDate}
                            />
                        </Form.Item>
                        {!isHalfDay ? (
                            <>
                                <Form.Item
                                    label="End Date"
                                    style={{ marginBottom: 5 }}
                                    name="endDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        format="DD-MMM-YYYY"
                                        onChange={onChangeEndDate}
                                    />
                                </Form.Item>
                            </>
                        ) : null}
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title={'Update Leave'}
                open={updateLeaveModalVisible}
                onOk={updateLeave}
                confirmLoading={loadingLeaves}
                onCancel={hideUpdateLeaveModal}
                okText={'Update'}
                destroyOnClose={true}
                width={600}
            >
                <h3>
                    {'Type - '}
                    {selectedLeaveRecord && selectedLeaveRecord.type}
                </h3>
                {updateLeaveModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Leave Count"
                            style={{ marginBottom: 5 }}
                            name="totalLeaves"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <InputNumber
                                min={0}
                                precision={2}
                                step={0.5}
                                autofocus
                            />
                        </Form.Item>
                        <Form.Item
                            label="Start Date"
                            style={{ marginBottom: 5 }}
                            name="startDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <DatePicker
                                format="DD-MMM-YYYY"
                                onChange={onChangeStartDate}
                            />
                        </Form.Item>
                        <Form.Item
                            label="End Date"
                            style={{ marginBottom: 5 }}
                            name="endDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <DatePicker
                                format="DD-MMM-YYYY"
                                onChange={onChangeEndDate}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            {compOffModalVisible ? (
                <IlEmployeeCompOff
                    hideCompOffModal={hideCompOffsModal}
                    employees={props.employees}
                    userId={selectedRecord.user.id}
                />
            ) : null}
        </>
    )
}

export default IlEmployeeLeave

export const useStyles = makeStyles()((theme) => ({
    leaveBalanceTableCell: {
        padding: 10,
        border: '1px solid black',
        borderCollapse: 'collapse',
        textAlign: 'left',
    },
}))
