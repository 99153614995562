import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Popconfirm,
    Table,
    Button,
    message,
    Radio,
    Select,
    Modal,
    Checkbox,
} from 'antd'
import clone from 'clone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Utils } from '../JS/Utils'

const PartnerInvoices = () => {
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState('unpaid')
    const [partnerId, setPartnerId] = useState(0)
    const [data, setData] = useState([])
    const [next, setNext] = useState()
    const [partnerOptions, setPartnerOptions] = useState([])
    const [invoiceModalVisible, setInvoiceModalVisible] = useState(false)
    const [selectedRecord, setSelectdRecord] = useState({})
    const [sendInvoiceToAdminsOnly, setSendInvoiceToAdminsOnly] =
        useState(false)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, partnerId])

    useEffect(() => {
        getPartners()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        setLoading(true)

        var url =
            GLOBAL.URL.GET_PARTNER_INVOICES +
            '?filter=' +
            filter +
            '&partnerId=' +
            partnerId

        RestApi.doGet(url)
            .then((res) => {
                var response = JSON.parse(res.data)
                setData(response.data)
                setNext(response.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const { Option } = Select
    const getPartners = () => {
        if (partnerOptions.size > 0) {
            return
        }

        RestApi.doGet(GLOBAL.URL.GET_PARTNERS).then((res) => {
            var options = []
            res.data.forEach((item) => {
                options.push(
                    <Option value={item.id} key={item.id}>
                        {item.name}
                    </Option>
                )
            })

            setPartnerOptions(options)
        })
    }

    const getNextData = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.BASE_URL + next)
            .then((res) => {
                var response = JSON.parse(res.data)

                var invoices = clone(data)
                invoices = [...invoices, ...response.data]

                setData(invoices)
                setNext(response.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const markPaidUnpaid = (record) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))

        let url = ''
        if (record.invoicePaid) {
            url = GLOBAL.URL.MARK_PARTNER_INVOICE_UNPAID
        } else {
            url = GLOBAL.URL.MARK_PARTNER_INVOICE_PAID
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                var invoices = clone(data)
                invoices.forEach((row) => {
                    if (record.id === row.id) {
                        row.invoicePaid = !record.invoicePaid
                        return
                    }
                })

                setData(invoices)

                message.destroy()
                message.success(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showInvoiceModal = (record) => {
        setInvoiceModalVisible(true)
        setSelectdRecord(record)
    }

    const hideInvoiceModal = () => {
        setInvoiceModalVisible(false)
    }

    const sendInvoice = () => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append(
            'payload',
            JSON.stringify({
                invoiceNumber: selectedRecord.invoiceNumber,
                adminsOnly: sendInvoiceToAdminsOnly,
            })
        )

        RestApi.doPost(GLOBAL.URL.SENT_PARTNER_INVOICE, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)
                hideInvoiceModal()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    var tableColumns = [
        {
            title: 'Paid',
            dataIndex: 'invoicePaid',
            key: 'id',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => markPaidUnpaid(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                        }}
                        loading={loading}
                    >
                        {record.invoicePaid ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Invoice #',
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
        },
        {
            title: 'Partner',
            render: (text, record) =>
                record.partner ? record.partner.name : '',
            key: 'partner.name',
        },
        {
            title: 'Value',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Course',
            render: (text, record) => (record.course ? record.course.name : ''),
            key: 'course.name',
        },
        {
            title: 'Attempt',
            render: (text, record) =>
                record.attempt ? record.attempt.attempt : '',
            key: 'attempt.attempt',
        },
        {
            title: 'Invoice',
            dataIndex: 'invoice',
            key: 'invoice',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                            fontSize: 11,
                        }}
                        loading={loading}
                        onClick={() => showInvoiceModal(record)}
                    >
                        Invoice
                    </Button>
                    {Utils.isSuperUserAdmin() ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                                deleteInvoice(record.invoiceNumber)
                            }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                style={{
                                    border: 'none',
                                    color: 'red',
                                    padding: 0,
                                    marginLeft: 10,
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    style={{ fontSize: 12 }}
                                />
                            </Button>
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ]

    const onChangeFilter = (e) => {
        setFilter(e.target.value)
    }

    const deleteInvoice = (invoiceNumber) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append(
            'payload',
            JSON.stringify({ invoiceNumber: invoiceNumber })
        )

        RestApi.doPost(GLOBAL.URL.DELETE_PARTNER_INVOICE, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                getData()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h1>Partner Invoices</h1>
            <div style={{ marginTop: 10 }}>
                <Radio.Group value={filter} onChange={onChangeFilter}>
                    <Radio.Button value="unpaid">Unpaid</Radio.Button>
                    <Radio.Button value="paid">Paid</Radio.Button>
                    <Radio.Button value="all">All</Radio.Button>
                </Radio.Group>
                <Select
                    placeholder="Filter by Partner"
                    onChange={(value) => {
                        value ? setPartnerId(value) : setPartnerId(0)
                    }}
                    showSearch
                    allowClear={true}
                    style={{ width: 200, marginLeft: 10 }}
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {partnerOptions}
                </Select>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                style={{ marginTop: 10 }}
                rowKey="id"
            />
            {next ? (
                <Button
                    type="primary"
                    size="small"
                    style={{ marginTop: 10 }}
                    onClick={() => getNextData()}
                >
                    Next
                </Button>
            ) : null}
            <Modal
                title={'Send Invoice'}
                visible={invoiceModalVisible}
                onOk={sendInvoice}
                confirmLoading={loading}
                onCancel={hideInvoiceModal}
                okText={'Send'}
                destroyOnClose={true}
                width={800}
            >
                {invoiceModalVisible ? (
                    <div>
                        <h4>{selectedRecord.invoiceNumber}</h4>
                        <h4>{selectedRecord.partner.name}</h4>
                        <h4>{selectedRecord.course.name}</h4>
                    </div>
                ) : null}
                <div style={{ marginTop: 20 }}>
                    <Checkbox
                        checked={sendInvoiceToAdminsOnly}
                        onChange={(e) =>
                            setSendInvoiceToAdminsOnly(e.target.checked)
                        }
                    >
                        <strong>Send to Admins only?</strong>
                    </Checkbox>
                </div>
            </Modal>
        </div>
    )
}

export default PartnerInvoices
