import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Descriptions,
    Button,
    Tag,
    Table,
    Input,
    message,
    Popconfirm,
    Select,
    Modal,
    notification,
    Checkbox,
    Form,
} from 'antd'
import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    CopyOutlined,
    EditOutlined,
} from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Paper from '@mui/material/Paper'
import { Utils } from '../JS/Utils'
import VdoCipherPlayer from '../Video/VdoCipherPlayer'
import { goBack } from '../../Util/ILUtil'
import { useParams } from 'react-router-dom'
import ILAttachmentFileList, {
    processClipboardAttachment,
} from '../ILShareComponents/ILAttachmentFileList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'

const ForumTopicView = ({ location, navigate }) => {
    const [loading, setLoading] = useState(false)
    const [topicId, setTopicId] = useState('')
    const [data, setData] = useState({
        forumGroup: {},
        user: {},
        threads: [],
        attachments: [],
        stream: {},
    })
    const [showReply, setShowReply] = useState(false)
    const [reply, setReply] = useState('')
    const [postingReply, setPostingReply] = useState(false)
    const [parentThread, setParentThread] = useState({})
    const [groupNames, setGroupNames] = useState([])
    const [updateGroupLoading, setUpdateGroupLoading] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState('')
    const [fileList, setFileList] = useState([])
    const [showImageModal, setShowImageModal] = useState(false)
    const [selectedThread, setSelectedThread] = useState({})
    const [updateThreadModalVisible, setUpdateThreadModalVisible] =
        useState(false)
    const [isPrivate, setIsPrivate] = useState(false)
    const [updateTitleModalVisible, setUpdateTitleModalVisible] =
        useState(false)
    const [postAsUsersList, setPostAsUsersList] = useState([])
    const [postAsUserId, setPostAsUserId] = useState('')
    const [playVideoLoading, setPlayVideoLoading] = useState(false)
    const [videoCreds, setVideoCreds] = useState({})
    const [showVdoCipherPlayer, setShowVdoCipherPlayer] = useState(false)
    const [closeTopicModalVisible, setCloseTopicModalVisible] = useState(false)
    const [closeTopicLoading, setCloseTopicLoading] = useState(false)
    const [imageSrc, setImageSrc] = useState('')
    const [form] = Form.useForm()
    const { id } = useParams()
    const [createTicketLoading, setCreateTicketLoading] = useState(false)

    useEffect(() => {
        setTopicId(id)
        getData(id)
        getGroupNames()
        getPostAsUsersList()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getPostAsUsersList = () => {
        if (Utils.userHasForumsAdminAccess()) {
            RestApi.doGet(GLOBAL.URL.GET_FORUM_POST_AS_USERS_LIST).then(
                (res) => {
                    setPostAsUsersList(res.data)
                }
            )
        }
    }

    const getData = (id) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_FORUM_TOPIC_DETAILS + id)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getGroupNames = () => {
        RestApi.doGet(GLOBAL.URL.GET_FORUM_GROUP_NAMES).then((res) => {
            var groupNames = []
            JSON.parse(res.data).forEach((item) => {
                groupNames.push(item.name)
            })
            setGroupNames(groupNames)
        })
    }

    const updatedAnsweredStatus = () => {
        setLoading(true)

        var url = GLOBAL.URL.CLOSE_TOPIC
        if (data.answered) {
            url = GLOBAL.URL.OPEN_TOPIC
        }

        var payload = {}
        payload.id = data.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.success(res.data)
                var data1 = data
                data1.answered = !data1.answered
                setLoading(false)
                setData(data1)
                goBack(navigate)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enableDisableTopic = () => {
        setLoading(true)

        var url = GLOBAL.URL.ENABLE_FORUM_TOPIC
        if (data.enabled) {
            url = GLOBAL.URL.DISABLE_FORUM_TOPIC
        }

        var payload = {}
        payload.id = data.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.success(res.data)
                var dataNew = data
                dataNew.enabled = !data.enabled
                setData(dataNew)
                setLoading(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const updatePublicTopic = () => {
        setLoading(true)

        var url = GLOBAL.URL.MARK_FORUM_TOPIC_PRIVATE
        if (data.private) {
            url = GLOBAL.URL.MARK_FORUM_TOPIC_PUBLIC
        }

        var payload = {}
        payload.id = data.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.success(res.data)
                var dataNew = data
                dataNew.private = !data.private
                setData(dataNew)
                setLoading(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const lockUnlock = () => {
        setLoading(true)

        var url = GLOBAL.URL.LOCK_FORUM_TOPIC
        if (data.locked) {
            url = GLOBAL.URL.UNLOCK_FORUM_TOPIC
        }

        var payload = {}
        payload.id = data.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.success(res.data)
                var dataNew = data
                dataNew.locked = !data.locked
                setData(dataNew)
                setLoading(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enableDisableThread = (thread) => {
        setLoading(true)

        var enabled = thread.enabled

        var url = GLOBAL.URL.ENABLE_FORUM_THREAD
        if (thread.enabled) {
            url = GLOBAL.URL.DISABLE_FORUM_THREAD
        }

        var payload = {}
        payload.id = thread.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.success(res.data)
                var dataNew = data
                dataNew.threads.forEach((record) => {
                    if (record.id === thread.id) {
                        record.enabled = !enabled
                        return
                    }
                })
                setData(dataNew)
                setLoading(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteThread = (threadId) => {
        setLoading(true)

        var payload = {}
        payload.id = threadId

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_THREAD, formbodyLevel)
            .then((res) => {
                message.success(res.data)
                getData(id)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const deleteTopic = () => {
        setLoading(true)

        var payload = {}
        payload.id = topicId

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_TOPIC_FORUM, formbodyLevel)
            .then((res) => {
                message.success(res.data)
                goBack(navigate)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const markAsBestAnswer = (threadId) => {
        setLoading(true)

        var payload = {}
        payload.id = threadId

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.MARK_THREAD_AS_BEST_ANSWER, formData)
            .then((res) => {
                message.success(res.data)
                getData(id)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const downloadAttachment = (id) => {
        RestApi.doGet(GLOBAL.URL.GET_DOWNLOAD_ATTACHMENT_URL + id).then(
            (res) => {
                setShowImageModal(true)
                setImageSrc(res.data)
            }
        )
    }

    const onReplyChange = (e) => {
        setReply(e.target.value)
    }

    const postReply = () => {
        if (!reply) {
            message.error('Reply needed!!')
            return
        }
        setPostingReply(true)

        var payload = {}
        payload.body = reply
        payload.forumGroupId = data.forumGroup.id
        payload.forumTopicId = data.id
        payload.isPrivate = isPrivate
        payload.postAsUserId = postAsUserId
        if (parentThread.id) {
            payload.parentThreadId = parentThread.id
        } else {
            payload.parentThreadId = 0
        }

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))
        fileList.forEach((file) => {
            formbodyLevel.append('file', file.originFileObj)
        })

        RestApi.doPost(GLOBAL.URL.POST_FORUM_REPLY, formbodyLevel)
            .then((response) => {
                notification['success']({
                    message: 'Reply posted!!',
                })

                setShowReply(false)
                setFileList([])
                setReply('')
                setCloseTopicModalVisible(true)
                getData(topicId)
            })
            .finally(() => {
                setPostingReply(false)
                setPostAsUserId('')
            })
    }

    const replyToThread = (record) => {
        setParentThread(record)
        setShowReply(true)
        setIsPrivate(false)

        setTimeout(() => {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
            })
        }, 100)
    }

    const navigateToUserDetails = (record) => {
        navigate('/userdetails/' + record.id, { record: record })
    }

    const handleGroupNameChange = (value) => {
        setSelectedGroup(value)
    }

    const handlePostAsUserChange = (value) => {
        setPostAsUserId(value)
    }

    const updateGroup = () => {
        setUpdateGroupLoading(true)

        var payload = {}
        payload.id = data.id
        payload.groupName = selectedGroup

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.UPDATE_FORUM_TOPIC_GROUP, body)
            .then((response) => {
                var dataNew = data
                dataNew.forumGroup.name = selectedGroup
                setSelectedGroup('')
                setData(dataNew)
                message.success(response.data)
            })
            .finally(() => {
                setUpdateGroupLoading(false)
            })
    }

    const closeImageModal = () => {
        setShowImageModal(false)
    }

    const showUpdateThreadModal = (record) => {
        setSelectedThread(record)
        setUpdateThreadModalVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                body: record.body,
            })
        }, 100)
    }

    const hideUpdateThreadModal = () => {
        setUpdateThreadModalVisible(false)
    }

    const updateThread = () => {
        form.validateFields(['body'])
            .then((values) => {
                setLoading(true)

                values.id = selectedThread.id

                let formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.UPDATE_FORUM_THREAD, formData)
                    .then((res) => {
                        message.success(res.data)
                        hideUpdateThreadModal()
                        getData(topicId)
                    })
                    .error(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showUpdateTitleModal = () => {
        setUpdateTitleModalVisible(true)
        setTimeout(() => {
            form.setFieldsValue({
                title: data.title,
            })
        }, 100)
    }

    const hideUpdateTitleModal = () => {
        setUpdateTitleModalVisible(false)
    }

    const updateTitle = () => {
        form.validateFields(['title'])
            .then((values) => {
                setLoading(true)

                values.id = data.id

                let formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.UPDATE_FORUM_TOPIC_TITLE, formData)
                    .then((res) => {
                        message.success(res.data)
                        hideUpdateTitleModal()
                        getData(topicId)
                    })
                    .error(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const playLinkedVideo = () => {
        setPlayVideoLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_FORUM_TOPIC_VIDEO_CREDS + topicId)
            .then((res) => {
                setVideoCreds(res.data)
                setShowVdoCipherPlayer(true)
            })
            .finally((resp) => {
                setPlayVideoLoading(false)
            })
    }

    const hideVdoCipherPlayer = () => {
        setShowVdoCipherPlayer(false)
    }

    const hideCloseTopicModal = () => {
        setCloseTopicModalVisible(false)
    }

    const closeTopic = () => {
        setCloseTopicLoading(true)

        var payload = {}
        payload.id = data.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.CLOSE_TOPIC, formbodyLevel)
            .then((res) => {
                if (location.key !== 'default') {
                    goBack(navigate)
                } else {
                    hideCloseTopicModal()
                    getData(id)
                }
            })
            .finally((error) => {
                setCloseTopicLoading(false)
            })
    }

    const { TextArea } = Input
    const { Option } = Select

    const threadColums = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 75,
            render: (body, record) => (
                <span>
                    <span>{record.id}</span>
                    {!record.private ? (
                        <>
                            <br />
                            <Button
                                type="link"
                                onClick={() => {
                                    showUpdateThreadModal(record)
                                }}
                                style={{ padding: 0 }}
                            >
                                <EditOutlined
                                    theme="twoTone"
                                    style={{ fontSize: 11 }}
                                />
                            </Button>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Reply',
            dataIndex: 'body',
            render: (body, record) => (
                <div>
                    {record.bestAnswer ? (
                        <Tag color="#87d068">Best Answer</Tag>
                    ) : null}
                    {record.parent ? (
                        <div
                            style={{
                                background: '#f2f2f2',
                                padding: 10,
                                border: '1px solid lightgray',
                                marginTop: 10,
                            }}
                        >
                            <label style={{ whiteSpace: 'pre-wrap' }}>
                                {record.parent.body}
                            </label>
                            <hr style={{ width: '25%', marginLeft: 0 }} />
                            <span style={{ fontSize: 11 }}>
                                {record.parent.user.firstName}{' '}
                                {record.parent.user.lastName}
                            </span>
                            {' ('}
                            <span style={{ fontSize: 10 }}>
                                {record.parent.date}
                            </span>
                            {')'}
                        </div>
                    ) : null}
                    <div style={{ marginTop: 10 }}>
                        {record.edited ? (
                            <>
                                <span style={{ color: 'Blue', fontSize: 12 }}>
                                    {'(updated)'}
                                </span>
                                <br />
                            </>
                        ) : null}
                        <label
                            style={{
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            {record.body}
                        </label>
                    </div>
                    {record.attachments.length > 0 ? (
                        <div>
                            <span style={{ fontSize: 11 }}>(attachments)</span>
                            <Table
                                columns={attachmentColumns}
                                dataSource={record.attachments}
                                pagination={false}
                                bordered={false}
                                showHeader={false}
                                size="small"
                            />
                        </div>
                    ) : null}
                    {!record.private ? (
                        <div style={{ marginTop: 10 }}>
                            <Button
                                type="default"
                                onClick={() => replyToThread(record)}
                                size="small"
                            >
                                Reply
                            </Button>
                            {!data.bestAnswerAvailable ? (
                                <Popconfirm
                                    title="Are you sure? Best answer can be set only once."
                                    onConfirm={() =>
                                        markAsBestAnswer(record.id)
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        type="default"
                                        size="small"
                                        style={{ marginLeft: 10 }}
                                    >
                                        Mark As Best Answer
                                    </Button>
                                </Popconfirm>
                            ) : null}
                        </div>
                    ) : (
                        <Tag color="#2db7f5">Private Note</Tag>
                    )}
                    {record.postedAsByUser && record.postedAsByUser.id ? (
                        <div style={{ marginTop: 10 }}>
                            <strong>
                                {'Answered by ' +
                                    record.postedAsByUser.firstName +
                                    ' ' +
                                    record.postedAsByUser.lastName}
                            </strong>
                        </div>
                    ) : null}
                </div>
            ),
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ margin: 0, padding: 0 }}
                        onClick={() => navigateToUserDetails(record.user)}
                    >
                        {record.user.firstName} {record.user.lastName}
                    </Button>
                    <br />
                    <span style={{ fontSize: 10 }}>{record.date}</span>
                </span>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text, record) => (
                <>
                    {!record.private ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => enableDisableThread(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            {record.enabled ? (
                                <CheckCircleTwoTone
                                    theme="twoTone"
                                    style={{ fontSize: 20 }}
                                />
                            ) : (
                                <CloseCircleTwoTone
                                    theme="twoTone"
                                    twoToneColor="#eb2f96"
                                    style={{ fontSize: 20 }}
                                />
                            )}
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteThread(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{ border: 'none', color: 'red' }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const attachmentColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            style: { padding: 'none' },
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0, margin: 0 }}
                    onClick={() => {
                        downloadAttachment(record.id)
                    }}
                >
                    {record.name}
                </Button>
            ),
        },
    ]

    var groupNameOptions = []
    groupNames.forEach((item) => {
        groupNameOptions.push(<Option value={item}>{item}</Option>)
    })

    const formItemLayout = {
        labelCol: {
            xs: { span: 16 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    var postAsUserOptions = []
    postAsUsersList.forEach((item) => {
        postAsUserOptions.push(
            <Option value={item.id} key={item.id}>
                {item.firstName + ' ' + item.lastName}
            </Option>
        )
    })

    const createTicket = () => {
        setCreateTicketLoading(true)

        var payload = {}
        payload.forumTopicId = data.id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.TICKETS.ADD_TICKET_FORM_FORUM_TOPIC, formData)
            .then((res) => {
                var dataClone = clone(data)
                dataClone.ticketId = res.data
                setData(dataClone)

                window.open('/ticket/' + res.data, '_blank')
            })
            .finally(() => {
                setCreateTicketLoading(false)
            })
    }

    const openLinkedTicket = () => {
        window.open('/ticket/' + data.ticketId, '_blank')
    }

    return (
        <div>
            <div>
                <Button
                    type="primary"
                    onClick={() => {
                        goBack(navigate)
                    }}
                    size="small"
                >
                    Go Back
                </Button>
                {data.paidUser ? (
                    <Tag color="purple" style={{ marginLeft: 10 }}>
                        Paid User
                    </Tag>
                ) : null}
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => updatedAnsweredStatus()}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="default"
                        iconPosition="end"
                        size="small"
                        style={{ marginLeft: 10 }}
                        icon={
                            data.answered ? (
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={GLOBAL.COLORAPP.BLUE}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            )
                        }
                    >
                        Answered
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisableTopic()}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="default"
                        iconPosition="end"
                        size="small"
                        style={{ marginLeft: 10 }}
                        icon={
                            data.enabled ? (
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={GLOBAL.COLORAPP.BLUE}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            )
                        }
                    >
                        Enabled
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => updatePublicTopic()}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="default"
                        iconPosition="end"
                        size="small"
                        style={{ marginLeft: 10 }}
                        icon={
                            !data.private ? (
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={GLOBAL.COLORAPP.BLUE}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            )
                        }
                    >
                        Public
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => lockUnlock()}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="default"
                        iconPosition="end"
                        size="small"
                        style={{ marginLeft: 10 }}
                        icon={
                            !data.locked ? (
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={GLOBAL.COLORAPP.BLUE}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            )
                        }
                    >
                        Unlocked
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteTopic()}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="default"
                        iconPosition="end"
                        size="small"
                        style={{ marginLeft: 10 }}
                        icon={<FontAwesomeIcon icon={faTrashAlt} color="red" />}
                    >
                        Delete
                    </Button>
                </Popconfirm>

                {data.ticketId ? (
                    <Button
                        type="default"
                        size="small"
                        style={{ marginLeft: 10 }}
                        onClick={openLinkedTicket}
                    >
                        Open Linked Ticket
                    </Button>
                ) : (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={createTicket}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="default"
                            size="small"
                            style={{ marginLeft: 10 }}
                            loading={createTicketLoading}
                        >
                            Create Ticket
                        </Button>
                    </Popconfirm>
                )}

                <Descriptions bordered style={{ marginTop: 10 }}>
                    <Descriptions.Item label="Date">
                        {data.date}
                    </Descriptions.Item>
                    <Descriptions.Item label="User">
                        <Button
                            type="link"
                            style={{ margin: 0, padding: 0 }}
                            onClick={() => navigateToUserDetails(data.user)}
                        >
                            {data.user.firstName} {data.user.lastName}
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Views">
                        {data.views}
                    </Descriptions.Item>
                    <Descriptions.Item label="Group">
                        <div>
                            <span>
                                {data.forumGroup.name}
                                {data.stream.course ? (
                                    <span
                                        style={{
                                            fontSize: 10,
                                            marginLeft: 5,
                                        }}
                                    >
                                        {'(' +
                                            data.stream.course +
                                            ' - ' +
                                            data.stream.level +
                                            ')'}
                                    </span>
                                ) : null}
                            </span>
                        </div>
                        <Select
                            placeholder="Update Group"
                            onChange={handleGroupNameChange}
                            defaultValue={data.forumGroup.name}
                            style={{ width: 200, marginTop: 10 }}
                        >
                            {groupNameOptions}
                        </Select>
                        <Button
                            type="primary"
                            style={{ marginLeft: 10 }}
                            onClick={() => updateGroup()}
                            loading={updateGroupLoading}
                            disabled={
                                !selectedGroup ||
                                selectedGroup === data.forumGroup.name
                            }
                            size="small"
                        >
                            Update
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Attachments">
                        {data.attachments.length > 0 ? (
                            <Table
                                columns={attachmentColumns}
                                dataSource={data.attachments}
                                pagination={false}
                                showHeader={false}
                                bordered={false}
                                size="small"
                            />
                        ) : null}
                    </Descriptions.Item>
                </Descriptions>

                <div style={{ marginTop: 10 }}>
                    <div>
                        <Tag color="blue">Title</Tag>
                        <CopyToClipboard
                            text={data.deepLink}
                            onCopy={() =>
                                notification['success']({
                                    message: 'Link copied!',
                                })
                            }
                        >
                            <span>
                                <Button
                                    type="link"
                                    style={{ padding: 0, fontSize: 10 }}
                                >
                                    <CopyOutlined />
                                    {' Deep Link'}
                                </Button>
                            </span>
                        </CopyToClipboard>
                    </div>
                    <Paper elevation={2} style={{ padding: 10 }}>
                        <Button
                            type="link"
                            onClick={() => {
                                showUpdateTitleModal()
                            }}
                            style={{ padding: 0 }}
                        >
                            <EditOutlined
                                theme="twoTone"
                                style={{ fontSize: 14 }}
                            />
                        </Button>
                        <span style={{ marginLeft: 10 }}>{data.title}</span>
                    </Paper>
                </div>

                <div style={{ marginTop: 10 }}>
                    <Tag color="blue">Description</Tag>
                    <Paper
                        elevation={2}
                        style={{
                            padding: 10,
                            whiteSpace: 'pre-wrap',
                            marginTop: 5,
                        }}
                    >
                        {data.body}
                        {data.hasCourseLinkedVideo ? (
                            <div style={{ marginTop: 10 }}>
                                {!showVdoCipherPlayer ? (
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={playLinkedVideo}
                                        loading={playVideoLoading}
                                    >
                                        Play Linked Video
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={hideVdoCipherPlayer}
                                    >
                                        Close Video
                                    </Button>
                                )}
                            </div>
                        ) : null}
                        {showVdoCipherPlayer ? (
                            <div style={{ marginTop: 10 }}>
                                <VdoCipherPlayer videoCreds={videoCreds} />
                            </div>
                        ) : null}
                    </Paper>
                </div>

                <span style={{ marginTop: 20, display: 'block' }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            replyToThread({})
                        }}
                        loading={postingReply || loading}
                        size="small"
                    >
                        New Reply
                    </Button>
                </span>

                <Table
                    columns={threadColums}
                    dataSource={data.threads}
                    pagination={false}
                    style={{ marginTop: 8 }}
                    size="small"
                    rowKey="id"
                />
                {showReply ? (
                    <Paper elevation={2} style={{ padding: 10, marginTop: 25 }}>
                        <h3>Your Reply:</h3>
                        {parentThread.id ? (
                            <>
                                <span>Replying to:</span>
                                <div
                                    style={{
                                        background: '#f2f2f2',
                                        padding: 10,
                                        border: '1px solid lightgray',
                                    }}
                                >
                                    <label
                                        style={{
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {parentThread.body}
                                    </label>
                                    <hr
                                        style={{
                                            width: '25%',
                                            marginLeft: 0,
                                        }}
                                    />
                                    <span style={{ fontSize: 11 }}>
                                        {parentThread.user.firstName}{' '}
                                        {parentThread.user.lastName}
                                    </span>
                                    {' ('}
                                    <span style={{ fontSize: 10 }}>
                                        {parentThread.date}
                                    </span>
                                    {')'}
                                </div>
                            </>
                        ) : null}
                        <TextArea
                            rows={4}
                            style={{ marginTop: 10 }}
                            onChange={onReplyChange}
                            onPaste={(e) =>
                                processClipboardAttachment(e, setFileList)
                            }
                            autoFocus
                        />
                        <div style={{ marginTop: 10 }}>
                            <Checkbox
                                onChange={(event) => {
                                    setIsPrivate(event.target.checked)
                                }}
                            ></Checkbox>
                            {' Private'}
                        </div>
                        <ILAttachmentFileList
                            attachmentFileList={fileList}
                            setAttachmentFileList={setFileList}
                            customStyle={{ marginLeft: 0 }}
                        />

                        {postAsUsersList.length ? (
                            <div style={{ marginTop: 10 }}>
                                <Select
                                    placeholder="Reply As User"
                                    onChange={handlePostAsUserChange}
                                    style={{ width: 300 }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    allowClear
                                >
                                    {postAsUserOptions}
                                </Select>
                            </div>
                        ) : null}
                        <Button
                            type="primary"
                            style={{ marginTop: 10 }}
                            onClick={postReply}
                            loading={postingReply}
                        >
                            Post
                        </Button>
                    </Paper>
                ) : null}
            </div>
            <Modal
                title="Image Preview"
                open={showImageModal}
                onCancel={closeImageModal}
                footer={null}
                destroyOnClose={true}
            >
                <a href={imageSrc} target="_blank" rel="noreferrer noopener">
                    Download
                </a>
                <img
                    src={imageSrc}
                    alt={imageSrc}
                    style={{ maxWidth: '100%', marginTop: 10 }}
                />
            </Modal>
            <Modal
                title="Update Reply"
                open={updateThreadModalVisible}
                confirmLoading={loading}
                onCancel={hideUpdateThreadModal}
                destroyOnClose={true}
                okText="Update"
                width={800}
                onOk={() => {
                    updateThread()
                }}
            >
                {updateThreadModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Reply"
                            name="body"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <TextArea rows={10} autoFocus />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title="Update Title"
                open={updateTitleModalVisible}
                confirmLoading={loading}
                onCancel={hideUpdateTitleModal}
                destroyOnClose={true}
                okText="Update"
                width={800}
                onOk={() => {
                    updateTitle()
                }}
            >
                {updateTitleModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <Input autoFocus />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title=""
                open={closeTopicModalVisible}
                confirmLoading={closeTopicLoading}
                onCancel={hideCloseTopicModal}
                destroyOnClose={true}
                okText="Yes"
                width={400}
                onOk={closeTopic}
            >
                <h3>Do you want to close the topic?</h3>
            </Modal>
        </div>
    )
}

export default ForumTopicView
