import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { DatePicker, Button, Radio } from 'antd'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import StatsConstants from './StatsConstants'
import clone from 'clone'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

const FILTER_PURCHASE_DATES = 'FILTER_PURCHASE_DATES'
const FILTER_JOINING_DATES = 'FILTER_JOINING_DATES'

function SubjectRevenue() {
    const { RangePicker } = DatePicker
    const dateFormat = 'DD-MMM-YYYY'

    const [loading, setLoading] = useState(false)
    const [mapData, setMapData] = useState(StatsConstants.INITIAL_BAR_OPTIONS)
    const [options, setOptions] = useState({})
    const [data, setData] = useState({ revenueStats: [], agingStats: [] })
    const [
        agingTransactionsRevenueMapData,
        setAgingTransactionsRevenueMapData,
    ] = useState([])
    const [
        agingTransactionsRevenueSplitOptions,
        setAgingTransactionsRevenueSplitOptions,
    ] = useState({})

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [joiningDateStats, setJoiningDateStat] = useState()
    const [selectedFilter, setSelectedFilter] = useState()

    useEffect(() => {
        drawChart()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const onChangeDateRange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const getData = () => {
        if (!startDate || !endDate) {
            return
        }

        setLoading(true)

        var payload = {}
        payload.startDate = startDate
        payload.endDate = endDate

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.STATS.GET_FIRST_PURCHASE_STATS
        if (selectedFilter === FILTER_JOINING_DATES) {
            url = GLOBAL.URL.STATS.GET_FIRST_PURCHASE_STATS_BY_JOINING_DATE
        }

        RestApi.doPost(url, formData)
            .then((res) => {
                if (selectedFilter === FILTER_PURCHASE_DATES) {
                    setData(res.data)
                } else {
                    setJoiningDateStat(res.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const drawChart = () => {
        var localData = []
        var count = []
        var labels = []

        data.revenueStats.forEach((row) => {
            labels.push(row.month)
            localData.push(row.mrp)
            count.push(row.count)
        })

        var revenueDataSet = {}
        revenueDataSet.type = 'bar'
        revenueDataSet.data = localData
        revenueDataSet.label = 'Revenue (lacs)'
        revenueDataSet.backgroundColor = '#4bc0c0'
        revenueDataSet.borderColor = '#4bc0c0'
        revenueDataSet.fill = true
        revenueDataSet.yAxisID = 'y-1'
        revenueDataSet.datalabels = { display: true }

        var countDataSet = {}
        countDataSet.type = 'line'
        countDataSet.data = count
        countDataSet.label = 'Count'
        countDataSet.borderColor = '#396ab1'
        countDataSet.fill = false
        countDataSet.yAxisID = 'y-2'

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(countDataSet)
        mapData.datasets.push(revenueDataSet)

        var options = clone(StatsConstants.CHART_OPTIONS_TWO_LEVELS)
        options.plugins.title.text = 'First Purchase Revenue'

        setMapData(mapData)
        setOptions(options)

        // Aging stats
        var labelsAgingTransactionsRevenue = []
        var countAgingTransactionsRevenue = []
        var agingTransactionsRevenueBackgroundColor = []

        var cnt = 0
        data.agingStats.forEach((row) => {
            labelsAgingTransactionsRevenue.push(row.label)
            countAgingTransactionsRevenue.push(row.mrpPercent)
            agingTransactionsRevenueBackgroundColor.push(
                StatsConstants.COLORS[cnt % (StatsConstants.COLORS.length - 1)]
            )

            cnt++
        })

        var agingTransactionsRevenueDataSet = {}
        agingTransactionsRevenueDataSet.data = countAgingTransactionsRevenue
        agingTransactionsRevenueDataSet.label = 'Aging Revenue Split'
        agingTransactionsRevenueDataSet.fill = true
        agingTransactionsRevenueDataSet.backgroundColor =
            agingTransactionsRevenueBackgroundColor

        var agingTransactionsRevenueMapData = {}
        agingTransactionsRevenueMapData.labels = labelsAgingTransactionsRevenue
        agingTransactionsRevenueMapData.datasets = []
        agingTransactionsRevenueMapData.datasets.push(
            agingTransactionsRevenueDataSet
        )

        var agingTransactionsRevenueSplitOptions = clone(
            StatsConstants.CHART_OPTIONS
        )
        agingTransactionsRevenueSplitOptions.plugins.title.text =
            'Aging Revenue Split'
        agingTransactionsRevenueSplitOptions.plugins.datalabels.font.size = 12
        agingTransactionsRevenueSplitOptions.indexAxis = 'y'

        setAgingTransactionsRevenueMapData(agingTransactionsRevenueMapData)
        setAgingTransactionsRevenueSplitOptions(
            agingTransactionsRevenueSplitOptions
        )
    }

    const onChangeStatusFilter = (e) => {
        setSelectedFilter(e.target.value)
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    return (
        <div>
            <h2>First Purchase Stats</h2>
            <div>
                <Radio.Group
                    onChange={onChangeStatusFilter}
                    value={selectedFilter}
                    style={{ marginBottom: 10 }}
                >
                    <Radio.Button
                        value={FILTER_PURCHASE_DATES}
                        key={FILTER_PURCHASE_DATES}
                    >
                        {'By Purchase Dates'}
                    </Radio.Button>
                    <Radio.Button
                        value={FILTER_JOINING_DATES}
                        key={FILTER_JOINING_DATES}
                    >
                        {'By Joining Dates'}
                    </Radio.Button>
                </Radio.Group>
            </div>
            {selectedFilter ? (
                <div>
                    <RangePicker
                        format={dateFormat}
                        placeholder={['Start Date', 'End Date']}
                        onChange={onChangeDateRange}
                    />
                    <Button type="link" onClick={getData}>
                        {loading ? (
                            <LoadingOutlined style={{ fontSize: 20 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 20 }} />
                        )}
                    </Button>
                    {selectedFilter === FILTER_PURCHASE_DATES &&
                    mapData.labels &&
                    mapData.labels.length ? (
                        <>
                            <div>
                                <Bar
                                    data={mapData}
                                    width={800}
                                    height={400}
                                    options={options}
                                />
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <Bar
                                    data={agingTransactionsRevenueMapData}
                                    options={
                                        agingTransactionsRevenueSplitOptions
                                    }
                                />
                            </div>
                        </>
                    ) : selectedFilter === FILTER_JOINING_DATES &&
                      joiningDateStats ? (
                        <div
                            dangerouslySetInnerHTML={createMarkupWeb(
                                joiningDateStats
                            )}
                            style={{ marginTop: 20 }}
                        />
                    ) : null}
                </div>
            ) : null}
        </div>
    )
}

export default SubjectRevenue
