import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Popconfirm,
    message,
    Modal,
    Input,
    Select,
    Form,
} from 'antd'
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { EditTwoTone } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'

function VideoVendor(props) {
    const [form] = Form.useForm()
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [selectedRecord, setSelectedRecord] = useState()
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [coursesVisible, setCoursesVisible] = useState(false)
    const [courseNames, setCourseNames] = useState([])
    const [courseAccessList, setCourseAccessList] = useState([])
    const [selectedCourse, setSelectedCourse] = useState()
    const [whitelistData, setWhitelistData] = useState([])
    const [whitelistVisible, setWhitelistVisible] = useState(false)
    const [addEditWhiteListVisbile, setAddEditWhiteListVisbile] =
        useState(false)
    const [selectedWhitelistRecord, setSelectedWhitelistRecord] = useState({})

    useEffect(() => {
        getVendors()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getVendors = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_ALL_VIDEO_VENDORS)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enableDisable = (record) => {
        setLoading(true)

        var url = GLOBAL.URL.ENABLE_VIDEO_VENDOR
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_VIDEO_VENDOR
        }

        var payload = {}
        payload.id = record.id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formData)
            .then((response) => {
                message.success(response.data)

                getVendors()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisable(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            showAddEditModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 11 }} />
                    </Button>
                    <span style={{ marginLeft: 10 }}>{record.name}</span>
                </>
            ),
        },
        {
            title: 'Secret',
            dataIndex: 'secretKey',
            key: 'secretKey',
            render: (text, record) => (
                <CopyToClipboard text={record.secretKey} onCopy={onCopy}>
                    <Button type="link" style={{ padding: 0 }}>
                        <CopyOutlined />
                        <span style={{ fontSize: 12 }}>{' Copy Secret'}</span>
                    </Button>
                </CopyToClipboard>
            ),
        },
        {
            title: 'Courses',
            dataIndex: 'courses',
            key: 'courses',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showCoursesModal(record)}
                >
                    Courses
                </Button>
            ),
        },
        {
            title: 'Whitelist',
            dataIndex: 'whitelist',
            key: 'whitelist',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showWhitelist(record)}
                >
                    Whitelist
                </Button>
            ),
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.updatedAt}</span>
            ),
        },
    ]

    const showAddEditModal = (record) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue({ name: record.name })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
        setSelectedRecord()
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 2 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateVendor = () => {
        form.validateFields(['name'])
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.ADD_VIDEO_VENDOR
                if (selectedRecord.id) {
                    url = GLOBAL.URL.UPDATE_VIDEO_VENDOR
                    values.id = selectedRecord.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        hideAddEditModal()
                        getVendors()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const onCopy = () => {
        message.success('Email copied!')
    }

    const showCoursesModal = (record) => {
        setSelectedRecord(record)
        setCoursesVisible(true)
        getCourseAccess(record.id)
        setSelectedCourse()

        if (courseNames.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES).then((res) => {
                setCourseNames(res.data)
            })
        }
    }

    const hideCoursesModal = () => {
        setCoursesVisible(false)
    }

    const getCourseAccess = (vendorId) => {
        RestApi.doGet(GLOBAL.URL.GET_VIDEO_VENDOR_ACCESS_LIST + vendorId)
            .then((res) => {
                setCourseAccessList(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const addCourseAccess = () => {
        if (!selectedCourse) {
            message.error('Select course!')
            return
        }

        setLoading(true)

        var payload = {}
        payload.vendorId = selectedRecord.id
        payload.course = selectedCourse

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.ADD_COURSE_ACCESS_VIDEO_VENDOR, formData)
            .then(() => {
                getCourseAccess(selectedRecord.id)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteCourseAccess = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_COURSE_ACCESS_VIDEO_VENDOR, formData)
            .then((response) => {
                message.success(response.data)
                getCourseAccess(selectedRecord.id)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const getCourseOptions = () => {
        var options = []
        courseNames.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    var courseColumns = [
        {
            title: '',
            dataIndex: 'delete',
            key: 'delete',
            width: 40,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                        deleteCourseAccess(record.id)
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="link">
                        <DeleteOutlined
                            theme="twoTone"
                            style={{ fontSize: 20, color: '#eb2f96' }}
                        />
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
    ]

    const showWhitelist = (record) => {
        setWhitelistVisible(true)
        setSelectedRecord(record)
        getWhitelist(record.id)
    }

    const hideWhitelist = () => {
        setSelectedRecord()
        setWhitelistVisible(false)
    }

    const getWhitelist = (id) => {
        setLoading(true)
        setWhitelistData([])

        RestApi.doGet(GLOBAL.URL.GET_VIDEO_VENDOR_WHITELIST_URLS + id)
            .then((res) => {
                setWhitelistData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const whitelistColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            showAddEditWhitelist(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 11 }} />
                    </Button>
                    <span style={{ marginLeft: 10 }}>{record.url}</span>
                </>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteWhitelistUrl(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddEditWhitelist = (record) => {
        setAddEditWhiteListVisbile(true)
        setSelectedWhitelistRecord(record)
        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue({ url: record.url })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditWhitelist = () => {
        setAddEditWhiteListVisbile(false)
        setSelectedWhitelistRecord()
    }

    const addEditWhitelistUrl = () => {
        form.validateFields(['url'])
            .then((values) => {
                setLoading(true)

                values.vendorId = selectedRecord.id

                var url = GLOBAL.URL.ADD_VIDEO_VENDOR_WHITELIST_URL
                if (selectedWhitelistRecord && selectedWhitelistRecord.id) {
                    url = GLOBAL.URL.UPDATE_VIDEO_VENDOR_WHITELIST_URL
                    values.id = selectedWhitelistRecord.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then(() => {
                        hideAddEditWhitelist()
                        getWhitelist(selectedRecord.id)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteWhitelistUrl = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_VIDEO_VENDOR_WHITELIST_URL, formData)
            .then((response) => {
                message.success(response.data)
                getWhitelist(selectedRecord.id)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h3>Video Vendors</h3>
            <div>
                <Button type="primary" onClick={showAddEditModal} size="small">
                    Add Vendor
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey="name"
                size="small"
                loading={loading}
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Vendor'}
                open={addEditModalVisible}
                onOk={addOrUpdateVendor}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord && selectedRecord.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={
                    selectedRecord && selectedRecord.name
                        ? selectedRecord.name + ' courses access'
                        : ''
                }
                open={coursesVisible}
                footer={null}
                onCancel={hideCoursesModal}
                width={800}
            >
                <h4 style={{ marginBottom: 0 }}>
                    Select course to give access:
                </h4>
                <Select
                    placeholder="Select Course Name"
                    style={{ marginTop: 8, marginBottom: 8, width: '100%' }}
                    onChange={(option) => {
                        setSelectedCourse(option)
                    }}
                    showSearch
                >
                    {getCourseOptions()}
                </Select>
                <Button type="primary" size="small" onClick={addCourseAccess}>
                    Add Access
                </Button>
                <h4 style={{ marginBottom: 0, marginTop: 15 }}>
                    Vendor has access to below courses:
                </h4>
                <Table
                    columns={courseColumns}
                    dataSource={courseAccessList}
                    pagination={false}
                    rowKey="courseName"
                    size="small"
                    loading={loading}
                    style={{ marginTop: 5 }}
                />
            </Modal>
            <Modal
                title={
                    selectedRecord && selectedRecord.name
                        ? selectedRecord.name + ' whitelist urls'
                        : ''
                }
                open={whitelistVisible}
                footer={null}
                onCancel={hideWhitelist}
                width={800}
            >
                {addEditWhiteListVisbile ? (
                    <>
                        <div>
                            <Form
                                form={form}
                                layout="horizontal"
                                {...formItemLayout}
                            >
                                <Form.Item
                                    label="URL"
                                    name="url"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Button
                                type={'primary'}
                                onClick={addEditWhitelistUrl}
                            >
                                {selectedWhitelistRecord &&
                                selectedWhitelistRecord.id
                                    ? 'Update'
                                    : 'Add'}
                            </Button>
                            <Button
                                style={{ marginLeft: 10 }}
                                onClick={hideAddEditWhitelist}
                            >
                                Cancel
                            </Button>
                        </div>
                    </>
                ) : (
                    <div>
                        <Button
                            type="primary"
                            onClick={showAddEditWhitelist}
                            size="small"
                        >
                            Add URL
                        </Button>
                    </div>
                )}
                <Table
                    columns={whitelistColumns}
                    dataSource={whitelistData}
                    pagination={false}
                    rowKey="name"
                    size="small"
                    loading={loading}
                    style={{ marginTop: 10 }}
                />
            </Modal>
        </div>
    )
}

export default VideoVendor
